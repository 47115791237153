



















































import { Component } from 'vue-property-decorator';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import NotificationRepository from '@/api/repositories/NotificationRepository';
import { Audience } from '@/enum/Audience.enum';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mixins } from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixin';

const notificationRepository: NotificationRepository = RepositoryFactory.get('notifications');

interface AudienceSelect {
    title: string,
    value: Audience
}

@Component({
    mixins: [validationMixin],
    validations: {
        title: { required },
        body: { required },
        audience: { required }
    }
})
export default class NotificationView extends mixins(ErrorMessageHandlerMixin) {
    private isLoading: boolean = false;

    private title: string = '';
    private body: string = '';
    private audience: Audience | null = null;

    private audiences: AudienceSelect[] = [
        { title: 'Drivers', value: Audience.DRIVER },
        { title: 'Users', value: Audience.USER }
    ];

    public async sendNotification(): Promise<void> {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        try {
            this.isLoading = true;

            await notificationRepository.sendNotification({
                title: this.title,
                body: this.body,
                type: this.audience!
            });

            this.$notifySuccessSimplified('NOTIFICATIONS.SEND_SUCCESS');
        } catch (e) {
            this.$notifyErrorSimplified('NOTIFICATIONS.SEND_ERROR');
        } finally {
            this.isLoading = false;
        }
    }
}
